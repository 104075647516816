import Head from 'next/head';
// import { useRouter } from "next/router";
import config from '@config';

const HeadComponent = ({ data, availableLocals, script }) => {
  const seoFieldsHandler = () => {
    if (data && data.length > 0) {
      return data.map(d => {
        const newTAG = [];
        let name = '';
        let content = '';
        let property = '';
        let href = '';
        let rel = '';
        if (d.tag === 'meta') {
          Object.entries(d.attributes).map(([k, v]) => {
            name = k.trim() === 'name' ? v : name;
            content = k.trim() === 'content' ? v : content;
            property = k.trim() === 'property' ? v : property;
            if (content !== '' && name !== '') {
              newTAG.push(<meta name={name} content={content} />);
            } else if (content !== '' && property !== '') {
              newTAG.push(
                <meta key={v} content={content} property={property} />
              );
            }
          });
        }
        if (d.tag === 'link' && d.attributes !== null) {
          Object.entries(d.attributes).map(([key, value]) => {
            href = key.trim() === 'href' ? value : href;
            rel = key.trim() === 'rel' ? value : rel;
            if (href !== '' && rel != '') {
              newTAG.push(<link key={value} href={href} rel={rel} />);
            }
          });
        }
        if (d.tag === 'title') {
          newTAG.push(<title>{d.content}</title>);
        }

        return newTAG;
      });
    }
  };

  const availableLocalsHandler = () => {
    // const router = useRouter();
    if (availableLocals && availableLocals.length) {
      return availableLocals.map((l, v) => {
        const separateLocal = l.locale?.split('_');
        const availLocals = separateLocal[1]
          ? `${separateLocal[0].toLowerCase()}-${separateLocal[1].toLowerCase()}`
          : `${separateLocal[0].toLowerCase()}`;
        const url = `${config.BASE_PATH}${l.value}`;
        return (
          <link key={v} rel="alternate" hrefLang={availLocals} href={url} />
        );
      });
    }
  };

  const setScript = () => {
    if (script) {
      return (
        <script
          dangerouslySetInnerHTML={{
            __html: script,
          }}
        ></script>
      );
    }
  };

  return (
    <Head>
      {setScript()}
      {availableLocalsHandler()}
      {seoFieldsHandler()}
      <link
        rel="icon"
        href={`${config.imgPath}/d/46272/1720450736-favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        href={`${config.imgPath}/d/46272/1690792477-isha_logo_black.svg`}
      />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=yes"
      />
      <meta name="theme-color" content="#e4ded4" />
      <meta name="MobileOptimized" content="width" />
      <meta name="HandheldFriendly" content="true" />
    </Head>
  );
};

export default HeadComponent;

/* Built In Imports */
import { useContext, useEffect } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import PageConfig from '@msr/Common/PageConfig';
import RenderSections from '@msr/Common/RenderSections';
import WrapLayout from '@msr/components/Layout/WrapLayout';
import Breadcrum from '@msr/components/Navigation/Breadcrum';
import HeadComponent from '@msr/components/Utility/HeadComponent';
import { AuthContext } from '@store/auth-context';

/* Services */
import { fetchDetails } from '@Services/msrService';
import { getLocale } from '@msr/components/Utility/Shared/SharedService';
import {
  fetchFooterDetailData,
  fetchHeaderMenuData,
} from 'services/commonService';

/**
 * @param {string} pageData - MahaShivratri Landing Page Data
 */
const MahaShivratri = ({ pageData }) => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.RegionAndLang('', 'en');
  }, [authContext]);

  // console.log(authContext);

  return (
    <WrapLayout pageData={pageData}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Breadcrum page={pageData?.breadcrumbs} region={''} lang={''} />
      <Box as="section" className="container" p="0">
        {pageData?.body?.length && pageData?.body[0]?.value.length
          ? pageData.body[0]?.value.map((section, i) => {
              return (
                <RenderSections
                  pageData={pageData}
                  section={section}
                  lang={''}
                  key={nanoid(4)}
                />
              );
            })
          : null}
      </Box>
      <PageConfig isLikeVisible={false} pageData={pageData} />
    </WrapLayout>
  );
};

export const getStaticProps = async ({ preview, previewData }) => {
  const headerMenuData = await fetchHeaderMenuData(
    getLocale('IN', 'en'),
    'msrmainmenu'
  );
  const footerData = await fetchFooterDetailData(
    getLocale('IN', 'en'),
    'msrfooter'
  );
  const pageData = await fetchDetails('', '', '/', {
    preview,
    data: previewData,
  });

  return {
    props: {
      headerMenuData: headerMenuData?.data || null,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      isPreview: preview ? true : false,
      menuType: 'msrmainmenu',
      lang: 'en',
    },
  };
};

export default MahaShivratri;
